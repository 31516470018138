'use client'

import { SessionProvider } from 'next-auth/react'

export interface SessionWrapperProps {
    children: React.ReactNode;
}

export default function NextAuthSessionProvider({ children }: SessionWrapperProps) {
    return (
        <SessionProvider>
            {children}
        </SessionProvider>
    )
}