import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    products: [],
    isLoading: true,
    error: null,
};

const slice = createSlice({
    name: "products",
    initialState,
    reducers: {
        getProducts: (state, action) => {
            state.isLoading = false;
            state.products = action.payload;
        },
    },
});

export default slice.reducer;

export const { 
    getProducts 
} = slice.actions;